// packages
import { z } from "zod";
import { useFormContext } from "react-hook-form";

// components
import { PetInfoEditor } from "@/shared/components/PetInfoEditor";
import { Badge, Button } from "@/shared/components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

// hooks
import { useModal } from "@/shared/hooks/useModal";
import { useBreeds } from "@/shared/hooks/useBreeds";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// utils
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { TOP_BREEDS } from "@/shared/utils/constants";

// types
import { FormStepProps, OtherProps } from "@/shared/types/Form";
import { Quote } from "@/shared/types/Quote.interface";
import { UsPolicyStepSchema } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import Strings from "@/shared/utils/Strings.constants";

type AllPolicyStepProps = z.infer<typeof UsPolicyStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllPolicyStepProps>;
type OtherKeys = Exclude<keyof AllPolicyStepProps, keyof Quote> | undefined;

export function PolicyEditor(props: FormStepProps<Quote, StepKeys, OtherProps | undefined>) {
    const { appState } = useAppLayerContext();
    const { underwriter, updateQuote, isQuoteUpdating, isMultiPetLinked } = appState;
    const { breeds } = useBreeds(underwriter);
    const { getValues, watch, setValue, reset } = useFormContext<AllPolicyStepProps>();
    const { watch: watchParent, getValues: getParentValues, setValue: setParentValue, reset: resetParent } = useFormParentContext<Quote>();
    const quoteId = watchParent(`id`);

    const policies = watch("policies");

    const modal = useModal();

    const handleAddPet = () => {
        const firstPetCoverageSettings = policies[0]?.coverageSettings;
        const newPolicy = QuoteDataUtils.createNewPolicyUS(isMultiPetLinked ? firstPetCoverageSettings : undefined);
        const updatedPolicies = [...policies, newPolicy];
        const currentValues = getValues();
        reset({ ...currentValues, policies: updatedPolicies });
    };

    const handleDeletePet = async (index: number) => {
        if (!(await modal.confirm())) {
            return;
        }

        const deletedPolicyId = policies[index]?.id;
        const filteredPolicies = policies.filter((_, i) => i !== index);
        const currentValues = getValues();
        const updatedValues = { ...currentValues, policies: filteredPolicies };
        reset(updatedValues);

        if (!!quoteId) {
            const parentValues = getParentValues();
            const updatedParentPolicies = parentValues.policies?.filter(p => p.id !== deletedPolicyId) ?? parentValues.policies;
            const updatedParentValues = { ...parentValues, policies: updatedParentPolicies };
            updateQuote?.mutate(updatedParentValues);
        }
    };

    return (
        <div className="flex flex-1 flex-col gap-6">
            {policies.map((field, index) => {
                return (
                    <div key={field?.id ?? `field-${index}`}>
                        <PetInfoEditor
                            petBreeds={breeds}
                            topBreeds={TOP_BREEDS}
                            policyIndex={index}
                            showAge={true}
                            allowDelete={policies?.length > 1}
                            onDelete={async () => await handleDeletePet(index)}
                            styles={{
                                wrapper: "grid grid-cols-2 gap-6 sm:grid-cols-11 lg:grid-cols-12",
                                fields: {
                                    name: "order-1 col-span-2 sm:col-span-7 lg:col-span-3",
                                    age: "order-2 col-span-2 sm:col-span-4 lg:col-span-2",
                                    species: "order-3 col-span-1 sm:col-span-3 lg:col-span-2",
                                    gender: "order-4 col-span-1 sm:col-span-3 lg:col-span-2",
                                    breed: "order-5 col-span-2 sm:col-span-5 lg:col-span-3"
                                }
                            }}
                        />
                    </div>
                );
            })}

            <div className="flex flex-col items-center justify-center gap-2 sm:flex-row sm:justify-start">
                <Button variant="ghost" startDecorator={<FontAwesomeIcon icon={faPlus} />} className="text-nowrap" onClick={handleAddPet} disabled={isQuoteUpdating}>
                    {Strings.ADD_PET}
                </Button>
                {/* todo: should we make this rounded breakpoint change universal for Badge component */}
                <Badge variant="secondary" className="rounded-md sm:rounded-full">
                    10% multi-pet discount on all additional pets
                </Badge>
            </div>
            <hr className="mb-6 border-stroke-primary" />
            {modal.render}
        </div>
    );
}
