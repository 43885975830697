import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { Toast } from "../components/Toast";
import { Notifications } from "../types/Notifications";

type UseNotificationsProps = {
    notifications: Notifications;
};

export const useNotifications = ({ notifications }: UseNotificationsProps) => {
    const { toasts } = notifications;

    useEffect(() => {
        if (toasts?.length) {
            toasts.forEach(toastItem => {
                if (toastItem.visible) {
                    toast.custom(
                        t => {
                            return (
                                <AnimatePresence>
                                    {t.visible && (
                                        <motion.div
                                            key={toastItem.id}
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0, transition: { delay: 1 } }}
                                            exit={{ opacity: 0, x: 20 }}
                                            transition={{ duration: 0.25 }}
                                        >
                                            <Toast
                                                isCloseDisabled={toastItem.isCloseDisabled}
                                                closeToast={() => {
                                                    if (toastItem.onClose) {
                                                        toastItem.onClose();
                                                    }
                                                    toast.dismiss(toastItem.id);
                                                }}
                                            >
                                                {toastItem.message}
                                            </Toast>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            );
                        },
                        {
                            id: toastItem.id,
                            duration: toastItem.duration,
                            position: toastItem.position
                        }
                    );
                } else {
                    toast.dismiss(toastItem.id);
                }
            });
        }
    }, [toasts]);
};
