import { z } from "zod";
import { PetSchema, OwnerSchema } from "@/shared/types/Quote.interface";
import { RegexUtils } from "@/shared/utils/RegexUtils";

// Step Schema
export const UsPolicyStepSchema = OwnerSchema.extend({
    policies: z.array(PetSchema),
    phone: z
        .string()
        .refine(value => value === "" || RegexUtils.phoneRegex.test(value), "Enter a valid mobile number")
        .optional()
});

export const PartialUsPolicyStepSchema = UsPolicyStepSchema.extend({ policies: z.array(PetSchema) }).deepPartial();

export type UsPolicyStepType = z.infer<typeof UsPolicyStepSchema>;
